$(function() {

    if ($('.parallax').length) {
        $('body').mousemove(function(e) {
            if ($(window).width() > 767) {
                parallaxIt(e, ".parallax-slide._one", 40);
                parallaxIt(e, ".parallax-slide._two", -20);
            } else {
                parallaxIt(e, ".parallax-slide._one", 10);
                parallaxIt(e, ".parallax-slide._two", -10);
            }
        });

        function parallaxIt(e, target, movement) {
            var $this = $(".parallax-container");
            var relX = e.pageX - $this.offset().left;
            var relY = e.pageY - $this.offset().top;

        TweenMax.to(target, 1, {
                x: (relX - $this.width() / 2) / $this.width() * movement,
                y: (relY - $this.height() / 2) / $this.height() * movement
            });
        }
    }
});