var Main = Main || {};

$(document).ready(function () {

    var modalLink = $('.js-modal-trigger');

    Main.openPopup = function (id) {
        var modal = $('.js-modal');

        if (!$('#'+id).length) return;

        $.each(modal, function() {
            if ($(this).attr('id') === id) {
                $(this).addClass('opened');
                $('body').addClass('overflow');
            }
        });

        return false;
    };

    Main.closePopup = function (id) {
        var modal = $('.js-modal');
        $.each(modal, function() {
            if ($(this).attr('id') === id) {
                $(this).removeClass('opened');
                $('body').removeClass('overflow');
            }
        });

        if ($('#player').length) {
            console.log($('#player').get(0));
            $('iframe').attr('src', $('iframe').attr('src'));
        }
    };

    $(document).on('click', '.js-modal-trigger', function(e) {
        var target = $(this).attr('data-target');

        e.preventDefault();
        Main.openPopup(target);
    });

    $('.js-close').on('click', function() {
        var target = $(this).data('target');

        Main.closePopup(target);
    });

    $(document).mouseup(function (e) {
        var modalW = $('.modal');
        if (modalW.has(e.target).length === 0 && !$('.js-modal.opened').is('._check-required')) {
            $('.js-modal').removeClass('opened');
            $('body').removeClass('overflow');
        }
    });

    $(this).keydown(function (eventObject) {
        if (eventObject.which == 27 && !$('.js-modal.opened').is('._check-required')) {
            $('body').removeClass('overflow');
            $('.js-modal').removeClass('opened');
        }
    });

    function openModalHash() {
        var hash = [],
            modal,
            i;

        $('.js-modal').each(function () {
            var id = $(this).attr('id');

            hash.push(id);
        });

        for (i = 0;i < hash.length; i++) {
            if ( '#'+hash[i] == window.location.hash && $('#'+hash[i]).length) {
                modal = hash[i];

                Main.openPopup(modal);
            }
        }
    }

    openModalHash();
});
