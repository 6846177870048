function sendGa(category, action, label) {
    ga('send', {
        hitType: 'event',
        eventCategory: category,
        eventAction: action,
        eventLabel: label
    });
}

$(function() {

    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
        $('html').addClass('_safari');
    };

    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    svg4everybody();

    $(window).on( "orientationchange", function(event) {
        console.log( "This device is in " + event.orientation + " mode!" );

        if(($(window).width() < 961) && (event.orientation === 'landscape')) {
            $('body').addClass('turn');
        } else {
            $('body').removeClass('turn');
        }
    });

    if ($('.js-sticky').length) {
        var stickyEl = $('.js-sticky');
        $.each(stickyEl, function() {
            var stickyContainer = $(this).closest('.tab');
            stickyContainer.on('scroll', function() {
                if($(this).scrollTop() > 0 && $(this).scrollTop() < $(this).find('.js-sticky-container').outerHeight() - stickyEl.height()) {
                    $(this).find('.js-sticky').css('top', $(this).scrollTop());
                };
                if($(this).scrollTop() === 0) {
                    $(this).find('.js-sticky').css('top', '0');
                }
            })
        })
    }

    if ($(window).width() > 1023) {
        $('.scrollable').mCustomScrollbar({
            callbacks:{
                onScroll: function(){
                    if ($(".js-scroll-to-text").length) {
                        if (this.mcs.top < - $(window).height() / 2) {
                            $(".js-scroll-to-text").fadeOut(700);
                        } else {
                            $(".js-scroll-to-text").fadeIn(700);
                        }
                    }
                }
            }
        });

        if ($('.tabs').length) {
            $('html, body, .global-wrapper, .tabs').on('scroll touchmove mousewheel', function(e){
                e.preventDefault();
                e.stopPropagation();
                return false;
            });
        }
    }

    if($('.js-product-mob').length) {
        var productSlider = new Swiper('.js-product-mob', {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            speed: 700,
            loop: true
        });

        productSlider.on('transitionEnd', function() {
            var index = productSlider.realIndex + 1;

            ga('send', 'pageview', 'page'+index);
        });
    }

    if ($('.form__field').length) {
        $('.form__field').on('change', function() {
            if( $(this).val()) {
                $(this).next('label').addClass('hide');
            } else {
                $(this).next('label').removeClass('hide');
            }
        })
    };

    if ($('.js-tabs').length) {
        $('.js-tab-control').on('click', function(e) {
            e.preventDefault();
            $('.js-tab-control').removeClass('active');
            $(this).addClass('active');
            var n = $(this).attr('data-number');
            $('.js-tab').removeClass('active');
            $.each($('.js-tab'), function() {
                if ($(this).attr('data-number') === n) {
                    $(this).addClass('active');
                }
            });
            window.location.hash = $(this).attr('data-number');
        })
    };

    $(document).on("click",".js-scroll-to-text",function(e){
        var href=$(this).attr("href"),target=$(href).parents(".mCustomScrollbar"); 
        if(target.length){
            e.preventDefault();
            target.mCustomScrollbar("scrollTo",href);
        }
    });

    if ($('.js-product-slider').length) {
        var productSlider = new Swiper('.js-product-slider', {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'fraction',
            },
            speed: 0,
            delay: 700,
            loop: true
        });

        productSlider.on('transitionEnd', function() {
            var index = productSlider.realIndex + 1;

            ga('send', 'pageview', 'page'+index);
        });
    };

    if ($('.js-tooltip-trigger').length) {
        $('.js-tooltip-trigger').hover(
            function() {
                var tooltips = $(this).closest('.product-slide').find('.js-tooltip');
                tooltips.removeClass('show');
                var n = $(this).attr('data-target');
                tooltips.eq(n).addClass('show');
            },
            function() {
                var tooltips = $(this).closest('.product-slide').find('.js-tooltip');
                if (!$('._mobile').length) {
                    tooltips.eq(1).removeClass('show');
                    tooltips.eq(0).addClass('show');
                }
            }
        )
    };

    if ($('.js-interview-slider').length) {
        var interviewSlider = new Swiper('.js-interview-slider', {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'fraction',
            },
        });

        interviewSlider.on('transitionEnd', function() {
            var index = interviewSlider.realIndex + 1;

            ga('send', 'pageview', 'page'+index);
        });
    };

    var tabLoad = function() {
        var lHash = window.location.hash.slice(1);
        var tabEls = $('.js-tab, .js-tab-control')
        tabEls.removeClass('active');
        if (lHash) {
            $.each(tabEls, function() {
                if ($(this).attr('data-number') == lHash ||  $(this).attr('data-name') == lHash) {
                    $(this).addClass('active');
                }
            })
        } else {
            $('.js-tab').eq(0).addClass('active');
            $('.js-tab-control').eq(0).addClass('active');
        };
        if (lHash == '112') {
            $('.js-tab-control').eq(0).addClass('active');
            if ($('.js-interview-slider').length) {
                interviewSlider.slideTo(interviewSlider.slides.length - 1);
            }
        }
    };

    tabLoad();

    $('.js-offer-trigger').hover(
        function() {
            $(this).closest('.offer-block').find('.js-offer').addClass('show');
        },
        function() {
            setTimeout(function() {
                $('.js-offer').removeClass('show');
            }, 1200);
        }
    );

    if ($('._mobile').length) {

        $('.js-intro-trigger').on('click', function() {
            $(this).toggleClass('active');
            $('.js-intro-nav').toggleClass('opened');
        })
    }

    Main.openPopup('winnersPopup');

    $('.logo').on('click', function () {
        sendGa('logo', 'click', 'weleda');
    });

    $('.js-product-link[data-target="1"]').on('click', function () {
        sendGa('main link', 'click', 'interview');
    });

    $('.js-product-link[data-target="2"]').on('click', function () {
        sendGa('main link', 'click', 'about');
    });

    $('.js-product-link[data-target="3"]').on('click', function () {
        sendGa('main link', 'click', 'products');
    });

    $('.product-slide__product-title a, .product-slide__info-link').on('click', function () {
        sendGa('link', 'click', 'product');
    });

    $('.js-modal-trigger').on('click', function () {
        sendGa('modal', 'click', 'form');
    });
});