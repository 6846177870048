$(function() {
    function validateField(field) {
        var isValid = field.validity.valid;
        var fieldContainer = $(field).parents('form');
        if (isValid) {
            fieldContainer.removeClass('has-error');
        } else {
            fieldContainer.addClass('has-error');
        }
        return isValid;
    }

    function validateForm(elForm) {
        var errors = 0;
        elForm.querySelectorAll('input, textarea').forEach(function(item) {
            var isValidField = validateField(item);
            if(!isValidField) {
                errors += 1;
            }
        });
        return errors;
    }

    var jsForm = $('.js-form');

    jsForm.each(function(){
        var self = $(this);
        var selfSubmit = self.find('.js-form-submit');

        selfSubmit.on('click', function(e) {
            e.preventDefault();

            var hasError = validateForm(self[0]);

            if (!hasError) {
                var request = $.ajax({
                    url: self.attr('action'),
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        'email': self.find('input[type="email"]').val(),
                        'name': self.find('input#username').val(),
                        'text': self.find('input#address').val(),
                        'page': window.location.origin
                    },
                    beforeSend: function () {
                        selfSubmit.attr('disabled', 'disabled');
                    }
                });
                request.done(function (response, textStatus, jqXHR) {
                    Main.closePopup('requestPopup');
                    self[0].reset();
                    selfSubmit.attr('disabled', false);

                    setTimeout(function(){
                        Main.openPopup('successPopup');
                    }, 300);
                });
                request.fail(function (jqXHR, textStatus) {
                    console.log("Request failed: " + textStatus);
                });
            }
        });
    });
});